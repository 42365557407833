import {
  QueryClient,
  useSuspenseQuery,
  useQuery,
  queryOptions,
} from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60_000,
    },
  },
});

/**
 * Create React Query functions for a given function.
 */
export function createReactQueryFns<
  Fn extends (...args: unknown[]) => unknown,
  Opt extends Parameters<Fn>[0],
  Result extends Awaited<ReturnType<Fn>>,
>({ fn }: { fn: (opt: Opt) => Promise<Result> }) {
  if (!fn.name) throw new Error(`Must pass in a named function!`);
  const createOptions = (opt: Opt) =>
    queryOptions({
      queryKey: [fn.name, opt],
      queryFn: () => fn(opt),
    });

  return {
    createOptions,
    useFnSuspense(opt: Opt) {
      const { data, error } = useSuspenseQuery<Result>(createOptions(opt));
      if (error) throw error;
      return data;
    },
    useFn(opt: Opt) {
      return useQuery<Result>(createOptions(opt));
    },
    /**
     * Can be used outside of React components.
     * Will prime cache.
     */
    async get(opt: Opt) {
      return queryClient.fetchQuery<Result>(createOptions(opt));
    },
  };
}
