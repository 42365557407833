import { groupBy } from '@knapsack/utils';
import { appApiGql } from '@/services/app-api-client';
import DataLoader, { BatchLoadFn } from 'dataloader';

type ImgResult = Awaited<
  ReturnType<typeof appApiGql.getDesignSrcNodeImages>
>['designSrcFile']['version']['nodes'][number];

const batchLoadFn: BatchLoadFn<
  { nodeId: string; fileId: string; version: string },
  ImgResult
> = async (
  allNodes: readonly { nodeId: string; fileId: string; version: string }[],
): Promise<ImgResult[]> => {
  // each API call to Figma must be for a single `fileId` and single `version` - but can be for multiple `nodeId`s
  // so we group by `fileId` and `version` and make multiple API calls
  const groupedNodes = groupBy(
    allNodes,
    (node) => `${node.fileId}-${node.version}`,
  );

  const results = await Promise.all(
    Object.values(groupedNodes).map(async (nodes) => {
      const [{ fileId, version }] = nodes;
      try {
        const data = await appApiGql.getDesignSrcNodeImages({
          fileId,
          version,
          nodeIds: nodes.map((node) => node.nodeId),
        });

        return {
          fileId,
          version,
          nodes: data?.designSrcFile?.version?.nodes,
          error: null,
        };
      } catch (e) {
        return {
          fileId,
          version,
          error: e instanceof Error ? e : new Error(e),
        };
      }
    }),
  );

  // must return array in same order as params
  return allNodes.map((node) => {
    const { nodes, error } = results.find(
      ({ fileId, version }) =>
        node.fileId === fileId && version === node.version,
    );
    return (
      error ||
      nodes.find(({ nodeId }) => nodeId === node.nodeId) ||
      new Error(`Node ${node.nodeId} not found for file ${node.fileId}`)
    );
  });
};

export const imageLoader = new DataLoader(batchLoadFn, {
  // https://github.com/graphql/dataloader/blob/main/README.md#caching
  cacheMap: new Map(),
  cacheKeyFn: ({ fileId, nodeId, version }) => `${fileId}-${version}-${nodeId}`,
  cache: true,
  maxBatchSize: 100,
});
